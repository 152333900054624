// Load Styles
import '../../scss/landing-vesting/main.scss';
import '../../scss/landing-vesting/fonts/GothamPro-Medium.woff';
import {initBootstrap} from "../bootstrap";

initBootstrap({
  tooltip: false,
  popover: false,
  toasts: false
});

import Web3Component, {from} from './wallet'
import $ from "jquery";

const settings = {
  "VIDI_TOKEN_ADDRESS": "0x32D5d65318b83A5F1B9553e01FE2EF58C077b484",
  "LOCKER_ADDRESS": "0x0B45e3801ffcB68DFbE754081460C2Ecb454732c",
  "PRESALE_ADDRESS": "0x08906BF030190d4F84061C7ABF4512EAe7c4C00C",
  "USDT_ADDRESS": "0xb331c0C84f58Bfda2769bBd28B9525d7aEF9ebD2",
  "RPC_NODES": [
    "https://data-seed-prebsc-2-s1.binance.org:8545/",
    "https://data-seed-prebsc-1-s3.binance.org:8545/",
    "https://data-seed-prebsc-2-s3.binance.org:8545/",
  ],
  "WITHDRAW_GAS_VALUE": "300000",
  "BUY_LOCATION_GAS_VALUE": "500000",
  "CHAIN_ID": 97,
  "BLOCKS_TO_READ": 4500
};

const toastAutoShow = async (type, text) => {
  const toastHolder = document.querySelector('.no-auto-toast');
  if (toastHolder) return;
  const toast = $(".toast");
  const toastHtml = document.querySelector('.toast')
  const toastText = toastHtml.querySelector('.text')
  toastHtml.classList.add(`toast__${type}`)
  toastText.innerHTML = text;
  setTimeout(() => toast.show(), 100);
  const delayTimeout = +toast.data("delay");
  setTimeout(() => {
    toast.hide()
    toastHtml.classList.remove(`toast__${type}`);
  }, delayTimeout);

}

const web3Component = new window.web3Component(settings)

const buySeedButton = document.querySelector('#buy-btn-seed')
const buyPrivateButton = document.querySelector('#buy-btn-private')
const connectButton = document.querySelector('#wallet-connect')

function connectWallet() {

  try {

    const statusWallet = web3Component.getProvider();

    if (statusWallet && statusWallet.isConnecting) {
      toastAutoShow('success', 'Кошелёк подключен')
      return true;
    }

    const connect = web3Component.connectWallet();
    if (connect) {
      toastAutoShow('success', 'Кошелёк подключен')
    }
    return connect;

  } catch (e) {
    toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
    console.error(e);
  }

}

function buySeed() {
  try {

    const connect = connectWallet();

    if (connect) {

      const buyEvent = web3Component.buySeed();

      console.log('butEvent', buyEvent)

      const statusWallet = web3Component.getProvider();

      console.log('buyEvent', buyEvent, statusWallet, web3Component)

    }

  } catch (e) {

    if (e.message.startsWith('Error: User rejected the transaction')) {
      toastAutoShow('error', 'Операция отменена пользователем')
    } else {
      console.error(e);
      toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
    }
  }
}

function buyPrivate() {
  try {

    const connect = connectWallet();

    if (connect) {

      const buyPrivate = web3Component.buyPrivate();

      console.log('buyPrivate', buyPrivate);

    }
  } catch (e) {

    console.log('error message', e.message)

    if (e.message.startsWith('Error: User rejected the transaction')) {
      toastAutoShow('error', 'Операция отменена пользователем')
    } else {
      console.error(e);
      toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
    }
  }
}


connectButton.addEventListener('click', connectWallet);
if (buySeedButton) {
  buySeedButton.addEventListener('click', buySeed);
}

if (buyPrivateButton) {
  buyPrivateButton.addEventListener('click', buyPrivate);
}



